import React from "react";
import styled from "styled-components";
import { AnimateSharedLayout } from "framer-motion";

import MotionBox from "../john-motion/motion-box";
import Button from "../globals/button";
import { gtagClickEvent } from "../../functions/util";

export default function InquireButtonGroup({
  currentPageIndex,
  setCurrentPageIndex,
  lastPage,
  isFormSubmitting,
}) {
  const showBackButton = currentPageIndex > 1 && !lastPage;
  const showNextButton = !lastPage;
  const showSubmitButton = lastPage;

  const sharedAnimationSettings = {
    animate: "visible",
    exit: "hidden",
    hiddenStyles: {
      scale: 0.5,
      y: "1rem",
    },
    transitionOverrides: {
      delay: 0,
      duration: 0.4,
    },
  };

  function handleBackClick() {
    setCurrentPageIndex(currentPageIndex - 1);
    gtagClickEvent(`form_inquiry_prev_${currentPageIndex - 1}`, "clicked");
  }

  return (
    <AnimateSharedLayout>
      <ButtonGroupWrapper data-page={currentPageIndex}>
        {showBackButton && (
          <MotionBox
            layoutId="backButton"
            key="back"
            {...sharedAnimationSettings}
          >
            <Button
              data-testid="button_previous_page"
              type="submit"
              text="Back"
              forwardClick={handleBackClick}
              color="paper"
              disabled={isFormSubmitting}
            />
          </MotionBox>
        )}
        {showNextButton && (
          <MotionBox
            layoutId="nextButton"
            key="next"
            {...sharedAnimationSettings}
          >
            <Button
              data-testid="button_next_page"
              type="submit"
              text="Next"
              color="paper"
              disabled={isFormSubmitting}
            />
          </MotionBox>
        )}
        {showSubmitButton && (
          <>
            <MotionBox
              layoutId="backButton"
              key="back"
              {...sharedAnimationSettings}
            >
              <Button
                data-testid="button_previous_page"
                type="submit"
                forwardClick={handleBackClick}
                color="paper"
                icon="ArrowLeft"
                disabled={isFormSubmitting}
              />
            </MotionBox>
            <MotionBox
              layoutId="submitButton"
              key="submit"
              {...sharedAnimationSettings}
            >
              <Button
                data-testid="button_submit_form"
                type="submit"
                color="golden"
                icon={isFormSubmitting ? null : "ArrowRight"}
                disabled={isFormSubmitting}
              >
                {isFormSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </MotionBox>
          </>
        )}
      </ButtonGroupWrapper>
    </AnimateSharedLayout>
  );
}
const ButtonGroupWrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 0.75rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(255, 248, 229, 0) 0%,
    #fff8e5 41.22%
  );

  @media ${({ theme }) => theme.device.tablet} {
    position: relative;
    background: none;
    padding: 0;
  }

  .button {
    margin: 0 0.5rem;
  }
`;
