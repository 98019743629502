import React from "react";
import styled from "styled-components";

import SvgLoader from "./svg-loader";
import Link from "./john-gatsby-helpers/link";

function CloseButton({ to, ...rest }) {
  return (
    <ButtonWrap to={to} {...rest}>
      <SvgLoader icon="IconX" />
    </ButtonWrap>
  );
}

const ButtonWrap = styled(Link)`
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CloseButton;
