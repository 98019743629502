import formData from "../data/form.json";

export default function getFormValueDisplayName(key, value) {
  return formData.pages
    .flatMap((page) => {
      const matchedQuestion = page.questions.find(
        (question) => question.question_key === key
      );
      return matchedQuestion?.options.find((option) => option.value === value)
        ?.title;
    })
    .filter(Boolean)?.[0];
}
