/**
 * Vendor
 */
import axios from "axios";
import React, { useState } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";

/**
 * Local Components
 */
import InquireProgress from "./inquire-progress";
import InquireInputs from "./inquire-inputs";
import CloseButton from "../close-button";

/**
 * Functions
 */
import getFormSchema from "../../functions/getFormSchema";
import getFormInitialValues from "../../functions/getFormInitialValues";
import { gtagClickEvent } from "../../functions/util";

// pre-fill form using query params (date yyyy-MM-dd)

// ?event_name=Test%20event%20name&event_type=party&desired_date=2021-06-10&desired_time=8am&head_count=124&desired_space=the_ballroom&full_name=test%20name&email=jvwagoner@gmail.com&phone=5555555555&additional_details=some%20additional%20message

// ?event_name=Test%20event%20name&event_type=party&desired_date=2021-06-10&desired_time=8am&head_count=124&desired_space=the_ballroom&full_name=test%20name&email=hi@john.design&phone=5555555555&additional_details=some%20additional%20message

export default function InquireForm({ formData, search }) {
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const currentPageData = formData.pages[currentPageIndex - 1];
  const currentPageQuestions = currentPageData?.questions || [];
  const schema = getFormSchema(currentPageQuestions);
  const searchParamObject = Object.fromEntries(new URLSearchParams(search));
  const initialValues = getFormInitialValues(
    searchParamObject,
    currentPageQuestions
  );
  const resolver = yupResolver(schema);

  const { control, handleSubmit, setError, getValues, formState } = useForm({
    defaultValues: initialValues,
    mode: "onTouched",
    resolver,
    shouldUseNativeValidation: true,
  });

  const totalPages = formData.pages.length;
  const lastPage =
    currentPageIndex === totalPages || currentPageIndex > totalPages;

  function onError(error) {
    Object.entries(error).forEach(([name, errorObj]) =>
      setError(name, { type: errorObj.type, message: errorObj.message })
    );
  }

  async function onSubmit(values) {
    if (!lastPage) {
      setCurrentPageIndex(currentPageIndex + 1);
      gtagClickEvent(`form_inquiry_next_${currentPageIndex + 1}`, "clicked");
    } else {
      const data = schema.cast(values);
      await axios
        .post("/.netlify/functions/sendEmail", JSON.stringify(data))
        .then((response) => {
          const hasErrors = response?.data?.some((_data) => !_data?.id);
          if (response.status !== 200 && !hasErrors) {
            console.error("error: ", response);
          } else {
            gtagClickEvent("form_inquiry_submitted", "clicked");
            navigate("/thank-you");
          }
        })
        .catch((error) => console.error(error));
    }
  }

  return (
    <InquireWrapper>
      <InquireProgress
        formData={formData}
        currentPageData={currentPageData}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
      />
      <InquireInputs
        control={control}
        currentPageData={currentPageData}
        currentPageIndex={currentPageIndex}
        currentPageQuestions={currentPageQuestions}
        formData={formData}
        getValues={getValues}
        handleSubmit={handleSubmit}
        lastPage={lastPage}
        onError={onError}
        onSubmit={onSubmit}
        setCurrentPageIndex={setCurrentPageIndex}
        isFormSubmitting={formState.isSubmitting}
      />
      <CloseButtonWrap>
        <CloseButton
          to="/"
          onClick={() => gtagClickEvent("form_inquiry_exit", "Clicked")}
        />
      </CloseButtonWrap>
    </InquireWrapper>
  );
}

const CloseButtonWrap = styled.div`
  position: fixed;
  top: 0.625rem;
  right: 0.625rem;
  z-index: 9999;

  svg,
  svg path {
    fill: ${({ theme }) => theme.colors.paper} !important;
  }

  @media ${({ theme }) => theme.device.tablet} {
    top: 1.6875rem;
    right: 1.6875rem;

    svg,
    svg path {
      fill: ${({ theme }) => theme.colors.black} !important;
    }
  }
`;

const InquireWrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: max-content;
  flex-direction: column;
  /* position: relative;
  z-index: 1; */

  @media ${({ theme }) => theme.device.tablet} {
    min-height: 100vh;
    height: 100vh;
    flex-direction: row;
  }
`;

InquireForm.propTypes = {
  formData: PropTypes.objectOf(PropTypes.array).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  search: PropTypes.any.isRequired,
};
