import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { format, parse as parseDate } from "date-fns";

import { Headline, Paragraph } from "../type";
import formatPhoneNumber from "../../functions/formatPhoneNumber";
import getFormValueDisplayName from "../../functions/getFormValueDisplayName";
import { makeHover } from "../../style/base-styles";

export default function InquireConfirmation({
  getValues,
  setCurrentPageIndex,
}) {
  const [
    full_name,
    email,
    phone,
    event_type,
    desired_space,
    desired_date,
    desired_time,
    head_count,
  ] = getValues([
    "full_name",
    "email",
    "phone",
    "event_type",
    "desired_space",
    "desired_date",
    "desired_time",
    "head_count",
  ]);

  return (
    <ConfirmSection>
      <ConfirmRow>
        <Paragraph>
          <U onClick={() => setCurrentPageIndex(2)}>{full_name}</U>
        </Paragraph>
        <Paragraph size="p-title">
          <U onClick={() => setCurrentPageIndex(2)}>{email}</U> •{" "}
          <U onClick={() => setCurrentPageIndex(2)}>
            {formatPhoneNumber(phone)}
          </U>
        </Paragraph>
      </ConfirmRow>
      <ConfirmRow>
        <Paragraph size="p-title-small">Your Message</Paragraph>
        <Headline tag="h3" lineLength="2">
          You’re inquiring about having{" "}
          <U onClick={() => setCurrentPageIndex(1)}>
            {getFormValueDisplayName("event_type", event_type)}
          </U>{" "}
          in{" "}
          <U onClick={() => setCurrentPageIndex(1)}>
            {getFormValueDisplayName("desired_space", desired_space)}
          </U>{" "}
          on{" "}
          <U onClick={() => setCurrentPageIndex(1)}>
            {format(
              parseDate(desired_date, "yyyy-MM-dd", new Date()),
              "MMMM do, yyyy"
            )}
          </U>{" "}
          at <U onClick={() => setCurrentPageIndex(1)}>{desired_time}</U> for{" "}
          <U onClick={() => setCurrentPageIndex(1)}>{head_count}</U> guests.
        </Headline>
      </ConfirmRow>
    </ConfirmSection>
  );
}
const U = styled.span`
  text-decoration: underline;
  position: relative;
  transition: color;
  transition-duration: ${({ theme }) => theme.animation.duration[100].css};
  transition-timing-function: ${({ theme }) =>
    theme.animation.timingFunction.css};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.grayLight};
  }

  ${makeHover`
    &:after {
      opacity: 1;
    }
  `}

  &:after {
    content: "edit";
    position: absolute;
    left: 50%;
    bottom: 1em;
    transform: translateX(-50%);
    font-family: "DomaineSansTextBold";
    font-size: ${({ theme }) => theme.fonts.titleSmall};
    line-height: 130%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.golden};
    padding: 0.2rem 0.3rem;
    padding-right: 0.1rem;
    border-radius: 0.25rem;
    margin: 0;
    opacity: 0;
    transition: opacity;
    transition-duration: ${({ theme }) => theme.animation.duration[100].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
  }
`;
const ConfirmSection = styled.div`
  display: grid;
  flex-direction: column;
  text-align: center;
  grid-row-gap: 2.625rem;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    grid-column-end: span 2;
  }
`;
const ConfirmRow = styled(motion.div)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: ${({ theme }) => theme.fonts.h3};
    line-height: 1.3;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fonts.h4};
    }
  }

  p {
    span:after {
      bottom: -2.2em;
    }
  }
`;
