import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

import MotionBox from "../john-motion/motion-box";
import { makeHover } from "../../style/base-styles";
import InputBasic from "./input-basic";

function SelectArrowDef() {
  return (
    <svg width="0" height="0">
      <defs>
        <clipPath id="caret">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 10c4.056 0 6 3.46 6 6h2c0-2.54 1.944-6 6-6V7c-2.76 0-5.12 2.17-6.098 4.163-.53 1.079-.748 1.868-.838 2.337h-.127c-.09-.469-.31-1.258-.839-2.337C10.12 9.17 7.76 7 5 7v3z"
            fill="#F5D171"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function ValidArrowDef() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11056 7.0219C9.60597 4.02757 12.7995 1.00689 17.5898 0V3.02068C13.6228 3.97051 11.4638 5.44143 9.60597 8.46211C8.14381 10.8395 8.09791 13.2177 8.09791 14L7.0498 14H6.58984H6C6 11.4604 4.05631 8 0 8V5C2.75993 5 5.11915 7.16952 6.09817 9.16336C6.62123 10.2286 6.84092 11.0124 6.93319 11.4829H7.06341C7.20502 10.1507 7.50362 8.23722 8.11056 7.0219Z"
        fill="#F5D171"
      />
    </svg>
  );
}

const itemAnimationSettings = {
  animate: "visible",
  exit: "hidden",
  hiddenStyles: {
    scale: 0.25,
    x: -10,
    y: 0,
  },
  transitionOverrides: {
    delay: 0,
    duration: 0.2,
  },
};

export default function InputGroup({
  component,
  question,
  name,
  onBlur,
  onChange,
  value,
  errorMessage,
  isValid,
  ...rest
}) {
  const Input = component || InputBasic;

  return (
    <InputWrap
      key={question.question_key}
      data-error={!!errorMessage}
      data-type={`${question.question_type}`}
      isValid={isValid}
    >
      <InputLabel
        className="p-title"
        data-required={question.required}
        htmlFor={name}
      >
        {question.title}
      </InputLabel>
      <Input
        data-testid={`input_${question.question_key}`}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={question.placeholder}
        value={value}
        {...rest}
      />
      {errorMessage && (
        <ErrorText
          className="p-small"
          data-testid={`error_text_${question.question_key}`}
        >
          {errorMessage}
        </ErrorText>
      )}
      <AnimatePresence>
        {isValid && (
          <ValidArrow {...itemAnimationSettings}>
            <ValidArrowDef />
          </ValidArrow>
        )}
      </AnimatePresence>
      <SelectArrowDef />
    </InputWrap>
  );
}

const ValidArrow = styled(MotionBox)`
  position: absolute;
  left: 1.188rem;
  bottom: 0.75rem;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  z-index: 1;
  top: 0.875rem;
  left: 1.125rem;
  line-height: 0.8125rem;

  &[data-required="true"] {
    &:after {
      content: "";
      position: absolute;
      right: -0.5em;
      top: 0.5em;
      width: 0.1875rem;
      height: 0.1875rem;
      border-radius: 0.1875rem;
      background-color: ${({ theme }) => theme.colors.red};
    }
  }
`;

const ErrorText = styled(motion.p)`
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  top: 0.875rem;
  right: 1.125rem;
  line-height: 0.8125rem;
`;

const InputWrap = styled.div`
  min-height: 4.5rem;
  display: flex;
  position: relative;
  transition: transform;
  transition-duration: ${({ theme }) => theme.animation.duration[100].css};
  transition-timing-function: ${({ theme }) =>
    theme.animation.timingFunction.css};
  will-change: transform;

  ${makeHover`
    transform: scale(1.01);

    input,
    select {
      background-color: rgba(255,255,255,.5);
    }

    &[data-type="date_picker"]:after,
    &[data-type="dropdown"]:after {
      transform: translate3d(0,-0.25rem,0);
    }
  `}

  &:active {
    transform: scale(1);

    &[data-type="date_picker"]:after,
    &[data-type="dropdown"]:after {
      transform: translate3d(0, 0.15rem, 0);
    }
  }

  input,
  select,
  textarea {
    border: 2px solid white;
    border-radius: 0.5rem;
    font-family: "DomaineSansTextRegular", sans-serif;
    font-style: normal;
    font-size: ${({ theme }) => theme.fonts.paragraph};
    line-height: 130%;
    resize: none;
    font-feature-settings: "ss05" on, "ss03" on, "ss07" on, "calt" off,
      "liga" off;
    transition: transform, background, color, border, padding;
    transition-duration: ${({ theme }) => theme.animation.duration[100].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    z-index: 0;

    &:focus,
    &:active {
      border: 2px solid ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.white};
      outline: none;
    }

    &::placeholder {
      color: rgba(15, 39, 7, 0.4);
    }
  }

  input {
    padding: ${({ isValid }) =>
      isValid
        ? "2.375rem 1.125rem 0.875rem 3.063rem"
        : "2.375rem 1.125rem 0.875rem"};
  }

  select,
  textarea {
    padding: ${({ isValid }) =>
      isValid
        ? "2.375rem 0.9rem 0.875rem 3.063rem"
        : "2.375rem 0.9rem 0.875rem"};
  }

  &[data-error="true"] {
    input,
    select,
    textarea {
      border: 2px solid ${({ theme }) => theme.colors.red};
    }
  }

  &[data-type="text_area"] {
    height: 7.5rem;
  }

  &[data-type="date_picker"]:after,
  &[data-type="dropdown"]:after {
    content: "";
    width: 1.5em;
    height: 1.5em;
    background-color: ${({ theme }) => theme.colors.golden};
    position: absolute;
    top: 2.375rem;
    right: 0.9rem;
    clip-path: url(#caret);
    transition: transform;
    transition-duration: ${({ theme }) => theme.animation.duration[100].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
    will-change: transform;
  }

  input[type="date"] {
    &::-webkit-datetime-edit-text {
      color: ${({ theme }) => theme.colors.grayLight};
      padding: 0 0.3em;
    }
    &::-webkit-inner-spin-button {
      display: none;
    }
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`;
