/**
 * Vendor
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Local Components
 */
import InquireForm from "../../components/inquire-form";

/**
 * Data
 */
import formData from "../../data/form.json";

export default function Template({ location: { search } }) {
  return <InquireForm formData={formData} search={search} />;
}

Template.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
