/**
 * Vendor
 */
import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

/**
 * Local Components
 */
import AnimatedText from "../john-motion/motion-headline";
import MotionBox from "../john-motion/motion-box";
import { Headline, Paragraph } from "../type";
import SvgLoader from "../svg-loader";

export default function InquireProgress({ formData, currentPageData }) {
  const itemAnimationSettings = {
    animate: "visible",
    hiddenStyles: {
      scale: 1,
      y: 0,
      height: 0,
    },
    visibleStyles: {
      height: "auto",
    },
    transitionOverrides: {
      delay: 0,
      duration: 0.5,
    },
  };

  return (
    <AnimatePresence>
      <ProgressWrapper>
        <MobileLogo hiddenStyles={{ x: 0, y: 20 }}>
          <SvgLoader color="paper" logo="LineLogo" />
        </MobileLogo>
        <MobileProgressSection>
          {formData.pages.map(({ title_active }, i) => (
            <Paragraph
              key={i}
              size="title"
              data-active={currentPageData?.title_active === title_active}
            >
              {i + 1}
            </Paragraph>
          ))}
        </MobileProgressSection>
        {formData.pages.map(({ title, title_active }, i) => (
          <ProgressItem key={i}>
            <ProgressSection>
              <Paragraph size="title-large">{i + 1}</Paragraph>
              {currentPageData?.title_active !== title_active && (
                <ItemInner {...itemAnimationSettings}>
                  <Paragraph size="title-small">{title}</Paragraph>
                </ItemInner>
              )}
            </ProgressSection>
            {currentPageData?.title_active === title_active && (
              <ItemInner {...itemAnimationSettings}>
                <Headline lineLength={0.5} size="h3">
                  <AnimatedText text={title_active} />
                </Headline>
              </ItemInner>
            )}
          </ProgressItem>
        ))}
      </ProgressWrapper>
    </AnimatePresence>
  );
}

const MobileLogo = styled(MotionBox)`
  transform: scale(0.66);
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.device.tablet} {
    transform: scale(1);
    /* display: none;
    visibility: hidden; */
    position: absolute;
    top: 3rem;
  }
`;

const MobileProgressSection = styled.div`
  display: flex;

  p {
    padding: 0 0.625rem;
    opacity: 0.2;
  }

  p[data-active="true"] {
    opacity: 1;
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: none;
    visibility: hidden;
  }
`;

const ProgressSection = styled.div`
  display: none;
  visibility: hidden;

  @media ${({ theme }) => theme.device.tablet} {
    display: initial;
    visibility: visible;
  }
`;

const ItemInner = styled(MotionBox)`
  overflow: hidden;
`;

const ProgressItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 3rem;
  }

  .title-small,
  .h3 {
    margin-bottom: 0;
  }
`;

const ProgressWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem ${({ theme }) => theme.layout.outerRailBase} 1.1875rem;

  @media ${({ theme }) => theme.device.tablet} {
    height: 100%;
    min-width: calc(
      17rem + (${({ theme }) => theme.layout.outerRailTablet} * 2)
    );
    padding: 3rem 0 3rem;
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */
  }
`;
