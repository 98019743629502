/**
 * Vendor
 */
import React from "react";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";

/**
 * Local Components
 */
import DynamicFormContainer from "./dynamic-form-container";
import MotionBox from "../john-motion/motion-box";
import InquireButtonGroup from "./inquire-button-group";

import InquireConfirmation from "./inquire-confirmation";

export default function InquireInputs({
  control,
  currentPageIndex,
  currentPageQuestions,
  formData,
  handleSubmit,
  isFormSubmitting,
  getValues,
  lastPage,
  onError,
  onSubmit,
  setCurrentPageIndex,
}) {
  const sharedAnimationSettings = {
    animate: "visible",
    hiddenStyles: {
      scale: 1,
      y: "1rem",
    },
    visibleStyles: {
      height: "auto",
    },
    transitionOverrides: {
      delay: 0,
      duration: 0.5,
    },
  };

  return (
    <AnimatePresence>
      <FormWrapper onSubmit={handleSubmit(onSubmit, onError)}>
        <InputsSection id="inputs-section" data-page={currentPageIndex}>
          {currentPageIndex === 3 && (
            <InquireConfirmation
              data={formData}
              setCurrentPageIndex={setCurrentPageIndex}
              getValues={getValues}
            />
          )}
          {currentPageQuestions.map((question) => (
            <MotionBox key={question.question_key} {...sharedAnimationSettings}>
              <Controller
                control={control}
                name={question.question_key}
                render={({
                  field: { name, onBlur, onChange, value },
                  fieldState: { isTouched, error },
                  formState: { isSubmitted },
                }) => (
                  <DynamicFormContainer
                    errorMessage={
                      isTouched || isSubmitted ? error?.message : ""
                    }
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    question={question}
                    value={value || ""}
                    isValid={value && isTouched && !error}
                  />
                )}
              />
            </MotionBox>
          ))}
        </InputsSection>
        <InquireButtonGroup
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          lastPage={lastPage}
          sharedAnimationSetting={sharedAnimationSettings}
          isFormSubmitting={isFormSubmitting}
        />
      </FormWrapper>
    </AnimatePresence>
  );
}

const InputsSection = styled.div`
  display: grid;
  width: 100%;
  max-width: 35rem;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 1fr 1fr;

    &[data-page="1"] {
      .box:nth-child(1),
      .box:nth-child(2) {
        grid-column-end: span 2;
      }
    }

    &[data-page="2"] {
      .box:nth-child(1) {
        grid-column-end: span 2;
      }
    }

    &[data-page="3"] {
      .box {
        grid-column-end: span 2;
      }
    }
  }
`;

const FormWrapper = styled(motion.form)`
  height: 100%;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 2.5rem;
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.paper};
  padding: ${({ theme }) => theme.layout.outerRailBase};
  padding-bottom: 8rem;
  flex: 1;

  @media ${({ theme }) => theme.device.tablet} {
    justify-content: center;
    padding: 6vw ${({ theme }) => theme.layout.outerRailtablet};
  }
`;
